import './fonts/basis-grotesque-bold-pro.woff';
import './fonts/basis-grotesque-bold-pro.woff2';

//
// https://babeljs.io/docs/en/babel-plugin-syntax-dynamic-import
//
import "core-js/modules/es.promise";
import "core-js/modules/es.array.iterator";

import barba from '@barba/core';

import ScrollHandler from './components/ScrollHandler';
import { FeedFilter } from './components/FeedFilter';
import InViewport from './components/InViewport';
import ColorScheme from './components/ColorScheme';
import TheMegaNavigation from './parts/TheMegaNavigation';
import TheSearchBar from './parts/TheSearchBar';
import { AjaxForm } from './components/AjaxForm';
import { handleConsentPreferences, toggleConsent } from './components/ConsentHandler';

//
// TODO: Might be smart fopr caching of modules
//
// const modules = new Map();
// async function loadModule(path) {
//   if (modules.has(path)) {
//     return modules.get(path);
//   }

//   const module = await import(path);
//   modules.set(path, module);

//   return module;
// }

// Returns the next <name> event of `target`.
function nextEvent(target, name) {
  return new Promise(resolve => {
    const callback = () => {
      target.removeEventListener('transitionend', callback);
      resolve();
    }

    target.addEventListener(name, callback, false);
  });
}

const nextTick = () => new Promise(resolve => window.requestAnimationFrame(resolve));
const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));
//
// Application
//
const ProjectName = {
  // Options / globals
  body: null,
  scrollHandler: null,
  viewportObserver: null,
  colorSchemeObserver: null,
  theMegaNavigation: null,

  init() {
    this.body = document.body;
    this.scrollHandler = new ScrollHandler(window);
    this.viewportObserver = new InViewport();
    this.colorSchemeObserver = new ColorScheme();
    this.theMegaNavigation = new TheMegaNavigation(this.body.querySelector('#TheMegaNavigation'));
    this.theSearchBar = new TheSearchBar(this.body.querySelector('#TheSearchBar'));

    //
    // On scroll direction change
    //
    this.scrollHandler.listen('change:direction', evt => this.body.classList.toggle('is-scrolling-down', evt.detail), false);
    this.scrollHandler.listen('change:abovefold', evt => this.body.classList.toggle('is-above-fold', evt.detail), false);
    this.scrollHandler.listen('top', evt => this.body.classList.remove('is-scrolling-down'), false);

    // Scroll to top
    const scrollToTopButton = document.getElementById('ScrollToTopButton');
    if (scrollToTopButton != null) {
      scrollToTopButton.addEventListener('click', () => {
        this.scrollHandler.goTo(0);
        this.body.classList.remove('is-scrolling-down');
      })
    }

    barba.init({
      transitions: [{
        name: 'default',
        beforeLeave: async ({current}) => {
          current.container.classList.add('before-leaving');
          // await nextTick();
          // if(current.namespace === 'homepage') {
          //   await nextTick();
          //   return nextEvent(current.container, 'transitionend');
          // }

          return nextTick();
        },

        leave: async ({ current }) => {
          await nextTick();

          // Close popovers
          this.theMegaNavigation.close();
          this.theSearchBar.close();

          this.body.classList.remove('is-scrolling-down');
          current.container.classList.remove('before-leaving');
          current.container.classList.add('is-leaving');

          return nextEvent(current.container, 'transitionend');
        },

        afterLeave: async ({ current }) => {
          // await nextTick();
          this.scrollHandler.scrollTo(0);
          current.container.classList.remove('is-leaving');
          current.container.classList.add('after-leave');

          this.destroyPage(current);

          return nextTick();
        },

        beforeEnter: ({ next }) => {
          this.scrollHandler.scrollTo(0);
          this.body.classList.add('is-above-fold');
          // Init js on next page
          this.initPage(next.container);

          next.container.classList.add('before-entring');

          // Pass classes
          this.body.classList.toggle('content-has-hero', next.container.classList.contains('has-hero'));
          this.body.dataset.namespace = next.namespace;

          if(next.namespace === 'cinema' || next.namespace === 'movies') {
            this.body.dataset.colorScheme = 'dark';
            this.colorSchemeObserver.isEnabled = false;
          } else {
            this.body.dataset.colorScheme = 'default';
            this.colorSchemeObserver.isEnabled = true;
          }

          return nextTick();
        },

        
        enter: ({ next }) => {
          next.container.classList.add('is-entring');
          next.container.classList.remove('before-entring');

          return nextEvent(next.container, 'transitionend');
        },
        afterEnter: ({ next }) => {
          next.container.classList.remove('is-entring');

          // Hack for having videos play after navigation
          [...next.container.querySelectorAll('.TheHero video')].forEach(video => video.play());
        }
      }]
    });

    this.initPage(document);
  },

  initPage(root) {
    this.initObservers(root);
    this.initGalleries(root);
    this.initAccordions(root);
    this.initMetaData(root);
    this.initTheFeatures(root);
    this.initDatePicker(root);
    this.initProgramFilter(root);
    this.initArchiveItems(root);
    this.initColorScheme(root);
    this.initVideoEmbeds(root);
    this.initVideoEmbeds(root);
    this.initVideoOverlays(root);
    this.initAjaxForms(root);
    this.initConsentHandling(root);
  },

  destroyPage(root) {
  },

  initVideoOverlays(root) {
    const els = [...root.querySelectorAll('.OverlayVideo')];
    if(els) {
      Promise.all([
        import('./components/BaseOverlay'),
        import('./components/VideoController')
      ]).then(([BaseOverlay, VideoController]) => {
        els.map((el) => {
          const overlay = new BaseOverlay.default(el);
          const controller = new VideoController.VideoController(el.querySelector('iframe'));

          overlay.listen('open', () => {
            controller.play();
          });

          overlay.listen('close', () => {
            controller.pause();
          });
        });
      });
    }
  },

  initVideoEmbeds(root) {
    const els = [...root.querySelectorAll('.VideoEmbed')];
    if(els) {
      import('./components/VideoEmbed').then(({ VideoEmbed }) => {
        els.map((el) => new VideoEmbed(el));
      });
    }
  },

  initArchiveItems(root) {
    const els = [...root.querySelectorAll('.ArchiveItem')];
    if(els) {
      import('./components/ArchiveItem').then(({ ArchiveItem }) => {
        els.map((el) => new ArchiveItem(el));
      });
    }
  },

  initDatePicker(root) {
    const els = [...root.querySelectorAll('.DatePicker')];
    if(els) {
      import('./components/DatePicker').then(module => {
        els.map((el) => new module.default(el));
      });
    }
  },

  initProgramFilter(root) {
    const el = root.querySelector('#TheProgramFilter');
    if(el) {
      import('./parts/TheProgramFilter').then((module) => {
        new module.default(el);
      });
    }
  },

  initObservers(root) {
    [...root.querySelectorAll('[data-in-viewport]')].forEach(el => this.viewportObserver.observe(el));
  },

  initTheFeatures(root) {
    const el = document.querySelector('#TheFeatures');
    if(el) {
      import('./parts/TheFeatures').then(module => {
        new module.TheFeatures(el);
      });
    }
  },

  initMetaData(root) {
    const els = [...root.querySelectorAll('[data-metadata-toggle]')];
    if(els) {
      import('./components/MetaData').then(module => {
        els.map((el) => new module.MetaData(el));
      });
    }
  },

  initAccordions(root) {
    const els = [...root.querySelectorAll('[data-expandable-header]')];
    if(els) {
      import('./components/BaseExpandable').then(module => {
        els.map((el) => new module.BaseExpandable(el));
      });
    }
  },

  initGalleries(root) {
    const els = [...root.querySelectorAll('.HorizontalGallery')];
    if(els) {
      import('./components/HorizontalGallery').then(module => {
        els.map((el) => {
          const gallery = new module.HorizontalGallery(el);

          // Quickfix for barabe prevent
          el.addEventListener('click', evt => {
            const link = evt.target.closest('a')?.href || null;
            if(link && !gallery.hasDragged_) {
              evt.preventDefault();
              barba.go(link)
            }
          }, true);
        });
      });
    }
  },

  initColorScheme(root) {
    [...root.querySelectorAll('[data-set-color-scheme]')].forEach(el => this.colorSchemeObserver.observe(el));
  },

  initAjaxForms(root) {
    [...root.querySelectorAll('.AjaxForm')].forEach(el => new AjaxForm(el));
  },

  initConsentHandling(root) {
    [...root.querySelectorAll('form[name="ConsentPreferences"]')]
      .forEach(form => {
        form.addEventListener('change', handleConsentPreferences, false);
        form.addEventListener('submit', handleConsentPreferences, false);
      });

    function onClickConsent(event) {
      const button = event.currentTarget;

      if(button.value !== 'accept' && button.value !== 'reject') {
        return;
      }

      toggleConsent(button.value === 'accept');

      const popup = button.closest('.TheConsentBanner');
      if(popup) {
        popup.parentNode.removeChild(popup);
      }
    }

    [...root.querySelectorAll('button[name="consent"]')]
      .forEach(button => button.addEventListener('click', onClickConsent, false));
  },

  initOnLoad() {
  }
};

document.addEventListener('DOMContentLoaded', () => ProjectName.init());
window.addEventListener('load', () => ProjectName.initOnLoad());

// Accept HMR as per: https://webpack.js.org/api/hot-module-replacement#accept
if (module.hot) {
  module.hot.accept();
}
