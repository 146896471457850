import BaseOverlay from '../components/BaseOverlay';
import BaseToggle from '../components/BaseToggle';

export default class TheMegaNavigation extends BaseOverlay {
  constructor(root) {
    super(...arguments);

    this._lastMouseOver = null;
    this._groupExpandables = [];
    this._mainNav = this.contentRoot.firstChild;
    this._secondaryNav = this.contentRoot.lastChild;

    this.registerEventHandlers();
  }

  registerEventHandlers() {
    this.onGroupMouseOverHandler = this.onGroupMouseOver.bind(this);
    this.onSecondaryNavMouseOverHandler = this.onSecondaryNavMouseOver.bind(this);

    [...this._mainNav.children].forEach(el => {
      // Handle mouseover on item
      el.firstChild.addEventListener('mouseenter', this.onGroupMouseOverHandler);

      if(el.classList.contains('is-group')) {
        this._groupExpandables.push(new BaseToggle(el.lastChild));
      }
    });

    [...this._secondaryNav.children].forEach(
      el =>
        el.firstChild.addEventListener('mouseenter', this.onSecondaryNavMouseOverHandler )
    );
  }

  onGroupMouseOver(evt) {
    const current = evt.currentTarget.parentElement;

    if(current === this._lastMouseOver) {
      return;
    }

    this._lastMouseOver?.classList.remove('last-hover');
    this._lastMouseOver = current;
    this._lastMouseOver.classList.add('last-hover');
    this.contentRoot.classList.add('has-main-hover');
  }

  onSecondaryNavMouseOver(evt) {
    this.contentRoot.classList.remove('has-main-hover');
  }

  close() {
    this._groupExpandables.forEach(group => group.close());

    this._lastMouseOver?.classList.remove('last-hover');
    this.contentRoot.classList.remove('has-main-hover');
    this._lastMouseOver = null;

    super.close();
  }
}
