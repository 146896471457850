import KunstnernesHus from 'kunstnernes-hus';

import {
  get,
  post
} from './helpers';

export * from './helpers';

export const siteUrl = KunstnernesHus.siteUrl || '/';

export function getEvents(section = '', startDate = '', endDate = '') {
  if(section) {
    return get(`${siteUrl}program/${section}.json?startDate=${startDate}&endDate=${endDate}`);
  } else {
    return get(`${siteUrl}program.json?startDate=${startDate}&endDate=${endDate}`);
  }
}

export function getEventsByYear(section, year) {
  return get(`${siteUrl}program/${section}/past/${year}.json`);
}

export function search(q) {
  return get(`${siteUrl}search.json?q=${q}`);
}

export function postAction(data) {
  return post(`${siteUrl}actions/${data.action}`, data);
}
