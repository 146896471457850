export default class ColorScheme {
  constructor() {
    this.root = document.body;
    this.isEnabled = true;
    this._observer = null;
    this.registerEventHandlers();
  }

  registerEventHandlers() {
    // Handlers
    this._handleOnIntersection = this._onIntersection.bind(this);

    this._observer = new IntersectionObserver(this._handleOnIntersection, {
      threshold: 0,
      rootMargin: `-20% 0px -20%`
    });
  }

  _onIntersection(entries) {
    if(!this.isEnabled) {
      return;
    }

    const intersectingTarget = entries.find(entry => entry.isIntersecting);
    this.root.dataset.colorScheme = intersectingTarget?.target.dataset.setColorScheme || 'default';
  }

  observe(target) {
    if (!target.dataset.setColorScheme) {
      throw 'data-set-color-scheme is required';
    }

    this._observer.observe(target);
  }

  unobserve(target) {
    this._observer.unobserve(target);
  }
}
