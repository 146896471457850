import BaseToggle from './BaseToggle';
import { FocusTrap } from './FocusTrap';

let overlaysOpen = 0;

export default class BaseOverlay extends BaseToggle {
  constructor(root) {
    super(...arguments);
    this.contentRoot = this.root.lastChild.firstChild;
    this._focusTrap = new FocusTrap(this.root);
  }

  toggle(state) {
    super.toggle(...arguments);
  }

  open() {
    if(this.isOpen) {
      return;
    }

    super.open();

    document.body.classList.add(`${this.name}-is-open`);
    overlaysOpen++;
    this.trapFocus();
    document.body.classList.toggle('has-overlay-open', overlaysOpen > 0);
  }

  close() {
    if(!this.isOpen) {
      return;
    }

    super.close();

    document.body.classList.remove(`${this.name}-is-open`);
    overlaysOpen--;
    this.releaseFocus();
    document.body.classList.toggle('has-overlay-open', overlaysOpen > 0);
  }

  trapFocus() {
    this._focusTrap?.trapFocus();
  }

  releaseFocus() {
    this._focusTrap?.releaseFocus();
  }
}

  // /**
  //  * Called when drawer finishes open animation.
  //  */
  // protected opened_() {
  //   this.adapter.trapFocus();
  // }

  // /**
  //  * Called when drawer finishes close animation.
  //  */
  // protected closed_() {
  //   this.adapter.releaseFocus();
  // }

// this.adapter.saveFocus();

// restoreFocus();

// saveFocus: () => this.previousFocus_ = document.activeElement,
// restoreFocus: () => {
//   const previousFocus = this.previousFocus_ as HTMLOrSVGElement | null;
//   if (previousFocus && previousFocus.focus &&
//       this.root.contains(document.activeElement)) {
//     previousFocus.focus();
//   }
// },
