// https://jasonwatmore.com/post/2020/04/18/fetch-a-lightweight-fetch-wrapper-to-simplify-http-requests
// https://davidwalsh.name/cancel-fetch
const _controller = new AbortController();
const { signal } = _controller;

export function get(url) {
  return _fetch(url, {
    method: 'GET',
  });
}

export function post(url, body) {
  return _fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    },
    body: JSON.stringify(body),
  });
}

export function put(url, body) {
  return _fetch(url, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    },
    body: JSON.stringify(body)
  });
}

export function deleteReq(url) {
  return _fetch(url, {
    method: 'DELETE'
  });
}

export function abort() {
  _controller.abort();
}

function _fetch(url, requestOptions) {
  // Attache signal
  requestOptions.signal = signal;

  return fetch(url, requestOptions).then(_handleResponse);
}

function _handleResponse(response) {
  return response.text().then(text => {
    const data = text && JSON.parse(text);

    if (!response.ok) {
      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }

    return data;
  });
}
