import BaseComponent from './BaseComponent';
import { formToObject } from '../helpers/fetch';
import { postAction } from '../api';

export class AjaxForm extends BaseComponent {
  constructor(root) {
    super(...arguments);

    this.root = root;
    this._isSubmitting = false;
    this._errorMessage = null;

    this.registerEventHandlers();
  }

  registerEventHandlers() {
    this.onSubmitHandler = this.onSubmit.bind(this);
    this.root.addEventListener('submit', this.onSubmitHandler);
  }

  onSubmit(evt) {
    evt.preventDefault();

    if(this._isSubmitting) {
      return;
    }
    this._isSubmitting = true;

    // Remove old error messgae
    if(this._errorMessage) {
      this.root.removeChild(this._errorMessage);
      this._errorMessage = null;
    }

    // Get formdata
    const data = formToObject(evt.target);

    postAction(data)
    .then(response => {
      if(response.success) {
        this._onSuccess(response.message);
      } else {
        this._updateFields(response.errors);
        this._addErrorMessage(response.message);
      }
    })
    .catch(error => {
      this._addErrorMessage();
      console.error(error);
    }).then(() => {
      this._isSubmitting = false;
    });
  }

  _onSuccess(message = '') {
    if(!message) {
      message = 'The form was successfully submitted!';
    }

    // Create success message
    const successEl = document.createElement('p');
    successEl.innerText = message;
    this.root.parentNode.insertBefore(successEl, this.root.nextSibling);
    this.root.parentNode.removeChild(this.root);
  }

  _updateFields(errors = null) {
    if(!errors) {
      return;
    }

    for (const [key, value] of Object.entries(errors)) {
      const field = this.root.elements[key];

      if(!field){
        return;
      }

      field.classList.add('has-error');

      const errorEl = document.createElement('p');
      errorEl.classList.add('is-error');
      errorEl.innerHTML = value;

      field.addEventListener('blur', () => {
        field.parentNode.removeChild(errorEl);
        field.classList.remove('has-error');
      }, { once: true });

      field.parentNode.insertBefore(errorEl, field.nextSibling);
    }
  }

  _addErrorMessage(message = '') {
    if(!message) {
      message = 'An error occurred, please try again later.'
    }

    if(!this._errorMessage) {
      this._errorMessage = document.createElement('p');
      this.root.appendChild(this._errorMessage);
    }

    this._errorMessage.innerHTML = message;
  }
}
