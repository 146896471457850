import BaseComponent from './BaseComponent';

export default class ScrollHandler extends BaseComponent {
  constructor(root) {
    super(...arguments);

    this.root = root; // document.documentElement,
    this.scrollTop = (this.root.scrollTop || this.root.pageYOffset) - (this.root.clientTop || 0);
    this.isForceScrolling = false;
    this.isScrollingDown = false;
    this.isAboveFold = this.scrollTop < window.innerHeight;
    this._rafId = null;

    this._onScrollHandler = this._onScroll.bind(this);

    // TODO: This should be maybe be able to be unregistered.
    this.root.addEventListener('scroll', this._onScrollHandler, {
      passive: true
    });

    // Trigger onload
    this._onScroll();
  }

  _onScroll() {
    if(this.isForceScrolling) {
      this.isForceScrolling = false;
    } else {
      const newScrollY = (this.root.scrollTop || this.root.pageYOffset) - (this.root.clientTop || 0);

      if(newScrollY !== this.scrollTop) {
        const lastScroll = this.scrollTop;
        this.scrollTop = Math.max(newScrollY, 0);

        if(this.scrollTop === 0) {
          this.isScrollingDown = false;
          this.emit('top', '');
        } else if (this.scrollTop > lastScroll && !this.isScrollingDown) {
          this.isScrollingDown = true;
          this.emit('change:direction', this.isScrollingDown);
        } else if (this.scrollTop < lastScroll && this.isScrollingDown) {
          this.isScrollingDown = false;
          this.emit('change:direction', this.isScrollingDown);
        }

        const viewportHeight = window.innerHeight;
        if(this.scrollTop > viewportHeight && this.isAboveFold) {
          this.isAboveFold = false;
          this.emit('change:abovefold', this.isAboveFold);
        } else if (this.scrollTop < viewportHeight && !this.isAboveFold) {
          this.isAboveFold = true;
          this.emit('change:abovefold', this.isAboveFold);
        }
      }
    }
  }

  scrollTo(y) {
    if (this.root.scrollTop) {
      this.root.scrollTop = y;
    } else {
      this.root.scroll(0, y);
    }

    this.scrollTop = y;
  }

  goTo(y) {
    if(this._rafId) {
      cancelAnimationFrame(this._rafId);
    }
    const tick = () => {
      // Ease
      let newY = this.scrollTop + Math.floor((y - this.scrollTop) / 8);
      if(newY === this.scrollTop || newY < 0) {
        this.scrollTo(newY);
        this.isForceScrolling = false;
      } else {
        this.isForceScrolling = true;
        this.scrollTo(newY);
        this._rafId = requestAnimationFrame(tick);
      }
    }

    this._rafId = requestAnimationFrame(tick);
  }
}
