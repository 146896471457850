import BaseComponent from './BaseComponent';

export default class BaseToggle extends BaseComponent {
  constructor(root) {
    super(...arguments);

    this.root = root;
    this.name = this.root.id;
    this._triggers = [];
    this.isOpen = false;

    if (!this.name) {
      throw 'A id need to be provided.';
    }

    this.registerListeners();
  }

  registerListeners() {
    this.onClickHandler = this.onClick.bind(this);

    // Initial triggers
    [...document.querySelectorAll(`[aria-controls="${ this.name }"]`)].forEach(trigger => this.registerTrigger(trigger));
  }

  registerTrigger(trigger) {
    trigger.addEventListener('click', this.onClickHandler, false);
    this._triggers.push(trigger);
  }

  onClick(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    this.toggle();
  }

  toggle() {
    this.isOpen ? this.close() : this.open();
  }

  _setAttributes() {
    this.root.setAttribute('aria-hidden', !this.isOpen);
    this._triggers.forEach(trigger => trigger.setAttribute('aria-expanded', this.isOpen));
  }

  open() {
    this.isOpen = true;
    this._setAttributes();
    this.emit('open');
  }

  close() {
    this.isOpen = false;
    this._setAttributes();
    this.emit('close');
  }
}
