import BaseOverlay from '../components/BaseOverlay';
import { search, abort } from '../api';
import debounce from '../helpers/debounce';
import { parseFromString } from '../helpers/dom';


export default class TheSearchBar extends BaseOverlay {
  constructor(root) {
    super(...arguments);

    this.isSearching = false;
    this._input = this.contentRoot.firstChild.firstChild;
    this._results = this.contentRoot.lastChild;
    // this._resultsArticles = this._results.firstChild;
    // this._resultsPages = this._results.lastChild;

    this._focusTrap.options.skipInitialFocus = true;

    this.registerEventHandlers();
  }

  registerEventHandlers() {
    this._handleOnResults = this.onResults.bind(this);
    this._handleOnError = this.onError.bind(this);
    this._handleOnStopTyping = this.onStopTyping.bind(this);
    // TypingDone function on key up with debounce
    this._input.addEventListener('keyup', debounce(this._handleOnStopTyping, 666), false);
  }

  onStopTyping() {
    this.search(this._input.value);
  }

  search(q) {
    if(this.isSearching) {
      // Abort current search
      abort();
    }

    if (!q) {
      // Empty if no query specified
      this.updateResults([]);
      return;
    }

    this.isSearching = true;
    this.contentRoot.classList.add('is-searching');
    search(q).then(this._handleOnResults).catch(this._handleOnError);
  }

  onResults({ data }) {
    this.updateResults(data);
  }

  updateResults(results) {
    this.isSearching = false;
    this.contentRoot.classList.remove('is-searching');

    // const pagesFragment = document.createDocumentFragment();
    const articlesFragment = document.createDocumentFragment();

    results.forEach((entry) => {
      if(entry.html) {
        const el = parseFromString(entry.html);

        if(el) {
          articlesFragment.appendChild(el);
        }
      }
      // TODO: Handle pages
      // else {
      //   const link = document.createElement('a');
      //   link.href = entry.url;
      //   link.innerText = entry.title;
      //   pagesFragment.appendChild(link);
      // }
    });

    // this._resultsPages.replaceChildren(pagesFragment);
    this._results.replaceChildren(articlesFragment);
  }

  noResults() {}

  onError(error) {
    // Most likely aborted
    // console.log(error);
  }

  open() {
    super.open();
    this._input.focus();
  }

  close() {
    super.close();
    this._input.blur();
  }
}
